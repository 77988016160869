<script lang="ts" setup>
import type { NotificationMessage, NotificationMessagePayload } from "~/types";

const MAX_TITLE_LENGTH = 37;
const MAX_MESSAGE_LENGTH = 64;

const props = defineProps<{
	data: NotificationMessage;
}>();

const emit = defineEmits<{
	(event: "clickMessage", payload: NotificationMessagePayload): void;
}>();

const { t } = useT();
const { refresh: refreshAppInitData } = useAppInitData();

const updateNotificationCenter = () => {
	setTimeout(() => {
		refreshAppInitData();
	}, 1000);
};

const { durationLeft, duration } = useCountdown({
	timestamp: props.data.dateEnd,
	onCountdownStop: updateNotificationCenter
});
const [isShowAll, toggleShowAll] = useToggle(false);

const isShowTimer = computed(() => {
	if (props.data.type !== "offer") {
		return false;
	}

	const isStartLessNow = new Date(props.data.dateBegin).getTime() <= new Date().getTime();
	const isEndMoreNow = new Date(props.data.dateEnd).getTime() > new Date().getTime();

	return isStartLessNow && isEndMoreNow;
});
const isLongMessage = computed(
	() => props.data.message?.length > MAX_MESSAGE_LENGTH || props.data.title?.length > MAX_TITLE_LENGTH
);

const handleClickOnMessage = ({
	needSendStatusToServer = false,
	shouldOpenLink = false
}: Partial<Omit<NotificationMessagePayload, "id">> = {}) => {
	if (isLongMessage.value && !isShowAll.value) {
		toggleShowAll();
		return;
	}

	dispatchGAEvent({
		event: "click_button",
		location: props.data.isReaded ? "earlier" : "new",
		button_name: "notification",
		type: props.data.systemAlias
	});

	emit("clickMessage", { id: props.data.ncMessageId, needSendStatusToServer, shouldOpenLink });
};

watch(duration, () => {
	if (duration.value < 1) {
		handleClickOnMessage();
	}
});
</script>
<template>
	<div
		class="notification-item"
		:class="{ 'is-read': data.isReaded, 'is-long-text': isLongMessage && !isShowAll }"
		@click.stop="handleClickOnMessage({ needSendStatusToServer: true, shouldOpenLink: true })"
	>
		<img :src="data.image?.replace('http:', 'https:')" :alt="data.title" width="36" height="36" />
		<div class="main-content">
			<AText class="title" :modifiers="['semibold']">{{ data.title }}</AText>
			<AText as="p" class="message">{{ data.message }}</AText>
			<AText
				v-if="isLongMessage"
				class="text-сirebon more-info"
				variant="topeka"
				:modifiers="['underline']"
				@click.stop="toggleShowAll()"
			>
				{{ isShowAll ? t("Less details") : t("More details") }}
			</AText>

			<div class="action">
				<AButton v-if="data.link" size="md" variant="primary">
					<AText :modifiers="['capitalize', 'medium']">
						{{ data.buttonLabel || t("More") }}
					</AText>
				</AButton>
				<AText v-if="isShowTimer && duration" variant="topeka" :modifiers="['normal']" class="text-сirebon">
					<i18n-t keypath="{key} left">
						<template #key>
							{{ durationLeft }}
						</template>
					</i18n-t>
				</AText>
			</div>
		</div>
	</div>
</template>
<style scoped lang="scss">
.notification-item {
	width: 100%;
	padding: 12px;
	display: flex;
	align-items: flex-start;
	gap: gutter(1.5);
	border: 1px solid var(--chifeng);
	background: var(--carletonville);
	border-radius: 12px;
	box-shadow: 0 30px 40px -10px rgba(11, 8, 12, 0.3);
	cursor: pointer;
	margin-top: gutter(0.5);

	&.is-read {
		background: none;
		cursor: auto;
	}

	&.is-long-text {
		.title,
		.message {
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	img {
		flex-shrink: 0;
	}

	.message {
		margin-top: gutter(1.25);
	}

	.more-info {
		&:hover {
			cursor: pointer;
			text-decoration: none;
		}
	}

	button {
		padding: gutter(0.625) gutter(1.25);
		min-width: 75px;
	}

	.main-content {
		flex: 1;
	}

	.action {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: gutter(0.5);
		margin-top: gutter(1.5);
	}
}
</style>
